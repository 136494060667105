<template>
    <div v-if="!loading">
        <b-table :data="documents"
                 hoverable
                 @click="row => $refs[row.id].click()">
            <template slot-scope="props">
                <b-table-column field="created_at" :label="$t('Date')" width="50">
                    {{ props.row.created_at | displayDate }}
                </b-table-column>
                <b-table-column field="name" :label="$t('Name')">
                    <div style="position:relative;">
                        <div style="visibility: hidden;">salut</div>
                        <a
                            :ref="props.row.id"
                            :href="props.row.file"
                            target="_blank"
                            class="has-ellipsis inset"
                        >
                            {{ props.row.name }}
                        </a>
                    </div>
                </b-table-column>
                <b-table-column :label="$t('Delete')" centered width="100">
                    <div class="icon-button"
                         @click.stop="confirmDeletion(deleteDocument, props.row)">
                        <DeleteIcon />
                    </div>
                </b-table-column>
            </template>
            <template slot="empty">
                <div class="has-text-grey has-text-centered">
                    <p>{{ $t('No documents could be found.') }}</p>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
    import DeleteIcon from '@/components/icons/Delete'

    import { mapActions, mapState } from 'vuex'
    import { DeletionConfirmationMixin, NotificationMixin } from '@/config/mixins'

    export default {
        components: { DeleteIcon },
        mixins: [ DeletionConfirmationMixin, NotificationMixin ],
        computed: { ...mapState('documents', [ 'documents', 'loading' ]) },
        methods: {
            ...mapActions('documents', [ 'remove' ]),
            deleteDocument(doc) {
                this.remove(doc)
                    .then(() => {
                        this.notifySuccess(this.$t('Successfully deleted.'))
                        this.$router.go({ name: 'documents' })
                    })
                    .catch(this.notifyError)
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "~@/assets/vars";

    .has-ellipsis {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .inset {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

    ::v-deep {
        .b-table {
            .table {
                background-color: transparent;
            }

            tr {
                th {
                    border-bottom: 0;

                    &:last-child {
                        visibility: hidden;
                        padding-right: 0;
                        font-size: 10px;
                        color: $greyish-brown;
                    }
                }

                &:not(.is-empty) {
                    td {
                        background-color: $white;
                        border-top-width: 1px;
                        border-top-color: $light-grey;
                        border-bottom: none;
                        height: 60px;
                        vertical-align: middle;

                        &:last-child {
                            border-left: 0px solid #d0d0d0;

                            svg {
                                cursor: pointer;
                            }
                        }

                        &:first-child {
                            border-right: 0px solid $greyish-brown;
                        }
                    }
                }
            }

            tbody {
                tr {
                    cursor: pointer;
                }
            }
        }
    }
</style>
